import store from "../../redux/store";
import {
    RELATION_LIST,
} from "../actions";

export const SET_USER_LIST  = "SET_USER_LIST";
export const FETCH_USER_LIST  = "FETCH_USER_LIST";
export const UNFOLLOW_USER  = "UNFOLLOW_USER";
export const FETCH_USER_POST_LIST  = "FETCH_USER_POST_LIST";
export const SET_USER_POST_LIST  = "SET_USER_POST_LIST";
export const SET_PENDING_LIST  = "SET_PENDING_LIST";
export const SWITCH_CLOSE_FRIEND  = "SWITCH_CLOSE_FRIEND";
export const FETCH_PENDING_LIST  = "FETCH_PENDING_LIST";
export const ACCEPT_REQUEST  = "ACCEPT_REQUEST";
export const FETCH_SUGGESTED_RELATION_LIST  = "FETCH_SUGGESTED_RELATION_LIST";
export const SET_SUGGESTED_RELATION_LIST  = "SET_SUGGESTED_RELATION_LIST";
export const SEND_REQUEST  = "SEND_REQUEST";
export const SET_CURRENT_USER  = "SET_CURRENT_USER";
export const LIKE_USER_POST = "LIKE_USER_POST"
export const SEARCH_RELATION = "SEARCH_RELATION"

export const GetRelationList = () => {
    store.dispatch({
        type: RELATION_LIST,
    })
}

export const FetchUserList = (user:string) => {
    store.dispatch({
        type: FETCH_USER_LIST,
        payload:{user}
    })
}

export const UnfollowUser = (user:string) => {
    store.dispatch({
        type: UNFOLLOW_USER,
        payload:{user}
    })
}

export const FetchUserPostList = (user:any) => {
    store.dispatch({
        type: FETCH_USER_POST_LIST,
        payload:{user}
    })
}

export const FetchPendingList = () => {
    store.dispatch({
        type: FETCH_PENDING_LIST,
    })
}

export const AcceptRequest = (user:any) => {
    store.dispatch({
        type: ACCEPT_REQUEST,
        payload:{user}
    })
}
export const SwitchCloseFriend = (user:any) => {
    store.dispatch({
        type: SWITCH_CLOSE_FRIEND,
        payload:{user}
    })
}

export const FetchSuggestedRelationList = () => {
    store.dispatch({
        type: FETCH_SUGGESTED_RELATION_LIST,
    })
}
export const SendRequest = (uuid:any) => {
    store.dispatch({
        type: SEND_REQUEST,
        payload:{uuid}
    })
}
